var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"5"}},[(_vm.ranking)?_c('h3',[_vm._v(" "+_vm._s(_vm.rankingName)+" ")]):_vm._e()]),_c('v-col',{attrs:{"cols":"12","lg":"5"}},[_c('v-text-field',{staticClass:"search-input ma-0",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"2"}},[(!_vm.hideAddScoreButton)?_c('v-btn',{staticClass:"add-score-button",attrs:{"color":"primary","dark":"","small":"","outlined":"","block":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){return _vm.$emit('addScore', _vm.ranking)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Score ")],1):_vm._e()],1)],1)],1),(_vm.ranking.scores.length > 0)?_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.computedHeaders,"items":_vm.ranking.scores,"item-class":_vm.rowClasses,"search":_vm.search,"dense":_vm.$vuetify.breakpoint.smAndDown,"mobile-breakpoint":"0","show-expand":!_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"item.comment",fn:function(ref){
var item = ref.item;
return [(item.oneCC)?_c('v-chip',{staticClass:"float-left mr-3 pr-1 pl-1",attrs:{"dark":"","x-small":"","tile":"","depressed":"","color":"orange"}},[_vm._v("1CC")]):_vm._e(),_c('v-spacer'),_c('span',{staticStyle:{"max-width":"100px","display":"block","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},[_vm._v(_vm._s(item.comment))])]}},{key:"item.player.name",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function () { return _vm.$emit('goToPlayer', item.player); }}},[_vm._v(_vm._s(item.player.name))])]}},{key:"item.rank",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatRank")(item.rank))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(_vm._s(item.comment))])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"float-left d-none d-sm-flex"},[(item.photo)?_c('v-tooltip',{attrs:{"top":"","content-class":"photo-tooltip-class","nudge-right":"50"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1 pl-1 pr-1",attrs:{"x-small":"","outlined":"","href":item.photo,"target":"_blank"}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-camera")])],1)]}}],null,true)},[_c('v-img',{attrs:{"src":item.photo,"max-width":"250"}})],1):_vm._e(),(item.replay)?_c('v-chip',{staticClass:"pl-1 pr-1",attrs:{"x-small":"","dark":"","tile":"","href":item.replay,"target":"_blank","color":"red"}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-play")])],1):_vm._e(),(item.inp)?_c('v-chip',{staticClass:"ml-1 pl-2 pr-2",attrs:{"x-small":"","href":item.inp,"target":"_blank"}},[_vm._v(" INP ")]):_vm._e()],1),(_vm.ranking.mode && _vm.ranking.mode.scoreType === 'timer')?_c('span',{staticClass:"float-right",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return (function () { return _vm.goToScore(item); }).apply(null, arguments)}}},[_vm._v(_vm._s(_vm._f("formatTime")(item.value)))]):_c('span',{staticClass:"float-right",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return (function () { return _vm.goToScore(item); }).apply(null, arguments)}}},[_vm._v(_vm._s(_vm._f("formatNumber")(item.value)))])]}}],null,false,1716475279)})],1):_vm._e()],1)],1)],1),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }