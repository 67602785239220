var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.history.length > 0)?_c('v-row',[_c('v-col',[_c('h1',[_vm._v(_vm._s(_vm.history[0].gameTitle))]),_c('h2',[_vm._v("by "+_vm._s(_vm.history[0].player.name))]),_c('h3',[_vm._v("on ranking "+_vm._s(_vm.rankingName))])])],1):_vm._e(),_c('v-row',[_c('v-col',[_c('shmup-table',{attrs:{"headers":_vm.headers,"items":_vm.history},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [(item.mode && item.mode.scoreType === 'timer')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatTime")(item.value))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.value))+" ")])]}},{key:"item.gapWithPreviousScore",fn:function(ref){
var item = ref.item;
return [(
              item.gapWithPreviousScore && item.gapWithPreviousScore !== 0
            )?_c('span',[_vm._v(_vm._s(item.gapWithPreviousScore)+"%")]):_vm._e()]}},{key:"item.1CC",fn:function(ref){
            var item = ref.item;
return [(item['1CC'])?_c('OneCCChip'):_vm._e()]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(_vm.canEdit)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editScore(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e()]}}])}),_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v("Graphical Evolution")]),_c('v-card-text',[_c('v-sparkline',{attrs:{"auto-draw":"","labels":_vm.labels,"value":_vm.value,"color":"orange","line-width":"0.5","padding":"16","label-size":"3"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }