var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('h1',[_vm._v("Compare Scores")])])],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.players,"item-value":"id","item-text":"name","hide-no-data":"","hide-selected":"","hide-details":"","label":"Player 1","placeholder":"Start typing to Search","outlined":""},on:{"change":function () { return _vm.$emit('selectPlayer1', this$1.selectedPlayer1); }},model:{value:(_vm.selectedPlayer1),callback:function ($$v) {_vm.selectedPlayer1=$$v},expression:"selectedPlayer1"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.players,"item-value":"id","item-text":"name","hide-no-data":"","hide-selected":"","hide-details":"","label":"Player 2","placeholder":"Start typing to Search","outlined":""},on:{"change":function () { return _vm.$emit('selectPlayer2', this$1.selectedPlayer2); }},model:{value:(_vm.selectedPlayer2),callback:function ($$v) {_vm.selectedPlayer2=$$v},expression:"selectedPlayer2"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"xs12":""}},[_c('shmup-table',{attrs:{"headers":_vm.headers,"items":_vm.versus,"sort-by":['scoreGap'],"sort-desc":[true],"loading":_vm.loading},on:{"click:row":function (row) { return _vm.$emit('goToGame', row); }},scopedSlots:_vm._u([{key:"item.score1.rank",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{
                      win: item.wonByPlayer1,
                      lose: !item.wonByPlayer1,
                    }},[_vm._v(_vm._s(_vm._f("formatRank")(item.score1.rank)))])]}},{key:"item.score2.rank",fn:function(ref){
                    var item = ref.item;
return [_c('span',{class:{
                      win: !item.wonByPlayer1,
                      lose: item.wonByPlayer1,
                    }},[_vm._v(_vm._s(_vm._f("formatRank")(item.score2.rank)))])]}},{key:"item.scoreGap",fn:function(ref){
                    var item = ref.item;
return [_c('span',{class:{
                      win: item.wonByPlayer1,
                      lose: !item.wonByPlayer1,
                    }},[_vm._v(_vm._s(item.scoreGap)+"%")])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }