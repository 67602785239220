var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"top":"","left":"","nudge-top":"10","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1 mb-10 mb-sm-13 mb-md-13 mb-lg-13",attrs:{"fixed":"","absolute":"","dark":"","fab":"","bottom":"","right":"","color":"orange"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-history")])],1)]}}])},[_c('v-list',{attrs:{"flat":"","dense":""}},[(_vm.recentlyViewedGames.length === 0 && _vm.myLastScores.length === 0)?_c('v-subheader',[_vm._v(" NO HISTORY ")]):_vm._e(),(_vm.recentlyViewedGames && _vm.recentlyViewedGames.length > 0)?_c('v-subheader',[_vm._v("RECENTLY VIEWED")]):_vm._e(),_vm._l((_vm.recentlyViewedGames),function(game){return _c('v-list-item',{key:("game-" + (game.id)),staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$emit('goToGame', game)}}},[_c('v-list-item-avatar',{attrs:{"tile":""}},[_c('cover',{attrs:{"url":game.cover}})],1),_c('v-list-item-title',{staticClass:"game-title"},[_vm._v(_vm._s(game.title))])],1)}),(
        _vm.recentlyViewedGames &&
        _vm.recentlyViewedGames.length > 0 &&
        _vm.myLastScores &&
        _vm.myLastScores.length > 0
      )?_c('v-divider'):_vm._e(),(_vm.myLastScores.length > 0)?_c('v-subheader',[_vm._v("LAST SCORES")]):_vm._e(),_vm._l((_vm.myLastScores),function(score){return _c('v-list-item',{key:("score-" + (score.id)),staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$emit('goToGame', score.game)}}},[_c('v-list-item-avatar',{attrs:{"tile":""}},[_c('cover',{attrs:{"url":score.game.cover}})],1),_c('v-list-item-title',{staticClass:"game-title"},[_vm._v(_vm._s(score.game.title))])],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }