var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[(_vm.scores.length > 0)?_c('h1',[_vm._v(_vm._s(_vm.scores[0].player.name)+"'s Scores")]):_c('h1',[_vm._v("This player has no scores")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('shmup-table',{attrs:{"items":_vm.scores,"headers":_vm.headers,"sort-by":['rank'],"sort-desc":[false]},scopedSlots:_vm._u([{key:"item.game.cover",fn:function(ref){
var item = ref.item;
return [_c('cover',{staticClass:"ma-1",attrs:{"url":item.game.cover,"alt":item.game.title,"width":"50","contain":true},on:{"click":function($event){return _vm.goToGame(item)}}})]}},{key:"item.game.title",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"game-title",on:{"click":function($event){return _vm.goToGame(item)}}},[_vm._v(_vm._s(item.game.title))])]}},{key:"item.platform.name",fn:function(ref){
var item = ref.item;
return [_c('platform-button',{attrs:{"name":item.platform ? item.platform.name : ''},on:{"click":function () { return _vm.goToPlatform(item); }}})]}},{key:"item.rank",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatRank")(item.rank))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateFromNow")(item.createdAt))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [(item.mode && item.mode.scoreType === 'timer')?_c('span',{on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return (function () { return _vm.goToScore(item); }).apply(null, arguments)}}},[_vm._v(_vm._s(_vm._f("formatTime")(item.value)))]):_c('span',{on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return (function () { return _vm.goToScore(item); }).apply(null, arguments)}}},[_vm._v(_vm._s(_vm._f("formatNumber")(item.value)))])]}},{key:"item.onecc",fn:function(ref){
var item = ref.item;
return [(item.onecc)?_c('one-c-c-chip'):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canEdit)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editScore(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }