import { render, staticRenderFns } from "./AddScore.vue?vue&type=template&id=376a95bc&"
import script from "./AddScore.vue?vue&type=script&lang=ts&"
export * from "./AddScore.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports