var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-card',{attrs:{"tile":""}},[_c('Cover',{attrs:{"url":_vm.game.cover,"alt":_vm.game.title}})],1)],1),_c('v-col',{staticClass:"pa-1"},[_c('v-menu',{attrs:{"offset-x":"","nudge-right":"5"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},'v-chip',attrs,false),on),[_c('v-avatar',{staticClass:"green darken-4",attrs:{"left":""}},[_vm._v(" "+_vm._s((_vm.game.platforms || []).length)+" ")]),_vm._v(" Platforms ")],1)]}}])},[_c('v-list',{attrs:{"color":"green","dense":""}},_vm._l((_vm.game.platforms),function(platform,index){return _c('v-list-item',{key:index,attrs:{"dark":"","color":"green"},on:{"click":function($event){return _vm.$emit('selectPlatform', platform)}}},[_c('v-list-item-title',[_vm._v(_vm._s(platform.name))])],1)}),1)],1),_c('v-menu',{attrs:{"disabled":!_vm.game.difficulties || _vm.game.difficulties.length === 0,"offset-x":"","nudge-right":"5"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"amber","text-color":"white"}},'v-chip',attrs,false),on),[_c('v-avatar',{staticClass:"amber darken-4",attrs:{"left":""}},[_vm._v(" "+_vm._s((_vm.game.difficulties || []).length)+" ")]),_vm._v(" Difficulties ")],1)]}}])},[_c('v-list',{attrs:{"color":"amber","dense":""}},_vm._l((_vm.game.difficulties),function(difficulty,index){return _c('v-list-item',{key:index,attrs:{"dark":"","color":"amber"}},[_c('v-list-item-title',[_vm._v(_vm._s(difficulty.name))])],1)}),1)],1),_c('v-menu',{attrs:{"disabled":!_vm.game.modes || _vm.game.modes.length === 0,"offset-x":"","nudge-right":"5"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"grey","text-color":"white"}},'v-chip',attrs,false),on),[_c('v-avatar',{staticClass:"grey darken-4",attrs:{"left":""}},[_vm._v(" "+_vm._s((_vm.game.modes || []).length)+" ")]),_vm._v(" Modes ")],1)]}}])},[_c('v-list',{attrs:{"color":"grey","dense":""}},_vm._l((_vm.game.modes),function(mode,index){return _c('v-list-item',{key:index,attrs:{"dark":"","color":"grey darken-4"}},[_c('v-list-item-title',[_vm._v(_vm._s(mode.name))])],1)}),1)],1)],1),_c('v-col',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideAddScoreButton),expression:"!hideAddScoreButton"}],staticClass:"mr-1 mb-3 elevation-5",attrs:{"tile":"","small":"","dark":"","color":"orange"},on:{"click":function($event){return _vm.$emit('addScore', { game: _vm.game })}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Score ")],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideGoToGameButton),expression:"!hideGoToGameButton"}],staticClass:"mr-1 mb-3 elevation-5",attrs:{"tile":"","small":"","dark":"","color":"orange"},on:{"click":function($event){return _vm.$emit('goToGamePage')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-gamepad-variant")]),_vm._v(" Game Page ")],1),_c('v-btn',{staticClass:"mr-1 mb-3",attrs:{"tile":"","small":"","depressed":"","target":"_blank","href":_vm.game.thread}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-eye")]),_vm._v(" Read Topic ")],1),(!_vm.hideConfigureButton)?_c('v-btn',{attrs:{"tile":"","small":"","depressed":""},on:{"click":function($event){return _vm.$emit('configureGame', _vm.game)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-cog")]),_vm._v(" Configure ")],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }